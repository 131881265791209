import React from 'react';
import './Banner.scss';

const Banner = () => {
  return (
    <div className='banner'>
      <p className='banner__text'>
        MONDAY TO SUNDAY 18 - LATE . MONDAY TO SUNDAY 18 - LATE . MONDAY TO
        SUNDAY 18 - LATE . MONDAY TO SUNDAY 18 - LATE . MONDAY TO SUNDAY 18 -
        LATE . MONDAY TO SUNDAY 18 - LATE . MONDAY TO SUNDAY 18 - LATE . MONDAY
        TO SUNDAY 18 - LATE . MONDAY TO SUNDAY 18 - LATE .
      </p>
    </div>
  );
};

export default Banner;
